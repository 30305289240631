<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<v-avatar size="160">
				<v-img :src="offer.imgURL" />
				<v-fade-transition>
					<v-overlay v-if="hover" absolute color="#036358">
						<v-btn small color="primary" @click="switchEditMode()"> <v-icon left>mdi-camera</v-icon> {{ $t('settings.update') }} </v-btn>
					</v-overlay>
				</v-fade-transition>
			</v-avatar>
		</v-hover>
		<v-dialog v-model="editMode" persistent max-width="600px">
			<v-card rounded="xl">
				<v-card-title>
					<span class="headline">{{ $t('companies.logo') }}</span>
					<v-spacer />
					<v-btn icon @click="switchEditMode()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid">
						<v-file-input
							v-model="logo"
							:label="$t('companies.logo')"
							@change="onLogoUpload"
							prepend-icon=""
							prepend-inner-icon="mdi-paperclip"
							rounded
							outlined
							hide-details="auto"
							class="pb-3"
						/>
					</v-form>

					<div v-if="logoCompressed && logoUrl" class="text-center">
						<h3>{{ $t('explorer.readyToUpload') }}</h3>
						<p>{{ logo.name }}</p>
						<v-avatar size="160">
							<v-img :src="logoUrl" />
						</v-avatar>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn color="error" text rounded plain @click="callDelete()">
						{{ $t('companies.deleteLogo') }}
					</v-btn>
					<v-spacer />
					<v-btn color="blue darken-1" text rounded plain @click="callUpdate()">
						{{ $t('settings.update') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Compressor from 'compressorjs'

export default {
	name: 'OfferLogo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false,
			editMode: false,
			valid: false,
			logo: null,
			logoCompressed: null,
			logoUrl: null
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	methods: {
		switchEditMode() {
			this.editMode = !this.editMode
		},
		onLogoUpload() {
			new Promise((resolve, reject) => {
				new Compressor(this.logo, {
					quality: 0.9,
					success: resolve,
					error: reject
				})
			})
				.then((result) => {
					this.logoCompressed = result
					this.logoUrl = URL.createObjectURL(this.logoCompressed)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callUpdate() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			const formData = new FormData()
			formData.append('logo', this.logoCompressed, this.logoCompressed.name)
			this.updateOfferLogo({ offerID: this.offer.id, formData }).then(() => {
				this.switchEditMode()
				this.logo = null
				this.logoCompressed = null
				this.logoUrl = null
				this.loading = false
			})
		},
		callDelete() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.deleteOfferLogo(this.offer.id).then(() => {
				this.switchEditMode()
				this.loading = false
			})
		},
		...mapActions('offers', ['updateOfferLogo', 'deleteOfferLogo'])
	}
}
</script>
